import { AxiosError } from "axios";

export interface Resolved {
  data: any;
  error: any;
}

export interface ResolvedV1 {
  resp: any;
  error: any;
}

export async function resolve(promise: Promise<any>): Promise<Resolved> {
  const resolved: Resolved = {
    data: null,
    error: null,
  };

  try {
    resolved.data = (await promise).data;
  } catch (e) {
    if (e instanceof AxiosError) {
      // Axios error occurred
      resolved.error = { message: 'Request failed', originalError: e };
      //console.log("resolved.error", resolved.error)
    } else {
      // Other error occurred
      resolved.error = { message: 'An error occurred', originalError: e };
      //console.log("resolved.error", resolved.error)
    }
  }

  return resolved;
}

export async function resolveV1(promise: Promise<any>): Promise<ResolvedV1> {
  const resolved: ResolvedV1 = {
    resp: null,
    error: null,
  };

  try {
    resolved.resp = (await promise).data;
  } catch (e) {
    if (e instanceof AxiosError) resolved.error = e;
    else throw e;
  }

  return resolved;
}
