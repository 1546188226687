import * as React from 'react';
import { Button, Typography, SvgIcon, Box, Card, CardActions, CardContent, CardMedia, Link } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import backgroundImage from '../../assets/images/bg.png';

const HomePage: React.FC = () => {
  return (
    <Box textAlign="center" marginTop={10} display="flex" flexDirection="column" alignItems="center">
      {/* Additional Image below cards */}
      <Box display="flex" justifyContent="center" gap={4} marginBottom={4}>
        <img src={backgroundImage} alt="Additional Context" style={{ maxWidth: '51%', height: '50%' }} />
      </Box>
      <Box display="flex" justifyContent="center" gap={4} marginBottom={4}>
        {/* Predict App Card */}
        <Card sx={{ maxWidth: 345, height: 220, boxShadow: 10, backgroundColor: '#fb923c'}}>
          {/*<CardMedia*/}
          {/*  component="img"*/}
          {/*  height="140"*/}
          {/*  image="/path/to/your/predict-app-image.jpg"  // Path to your image*/}
          {/*  alt="Predict App"*/}
          {/*/>*/}
          <CardContent sx={{ color: 'white' }}>
            <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
              Predict App
            </Typography>
            <Typography variant="body2" sx={{ my: 2 }} >
              Access powerful prediction tools to analyze and forecast data trends.
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center' }}>
            <Button size="medium" variant="contained" color="secondary" component={RouterLink} to="/home">
              Go to Predict App
            </Button>
          </CardActions>
        </Card>

        {/* Literature Database Card */}
        <Card sx={{ maxWidth: 345, height: 220, boxShadow: 10, backgroundColor: '#fb923c' }}>
          {/*<CardMedia*/}
          {/*  component="img"*/}
          {/*  height="140"*/}
          {/*  image="/path/to/your/database-image.jpg"  // Path to your image*/}
          {/*  alt="Literature Database"*/}
          {/*/>*/}
          <CardContent sx={{ color: 'white' }}>
            <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
              COD'HEM
            </Typography>
            <Typography variant="body2"  sx={{ my: 2 }}>
              Consolidated Database of High Entropy Materials
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center' }}>
            {/*<Button size="medium" variant="contained" color="secondary" component={RouterLink} to="/database">*/}

            {/*  <div className="flex flex-col">*/}
            {/*    <span>*/}
            {/*      COD'HEM*/}
            {/*    </span>*/}
            {/*    <span style={{fontSize: '9px', fontWeight: 300}}>*/}
            {/*      Literature Database*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</Button>*/}

            <Button size="medium" variant="contained" color="secondary">
              <Link href={process.env.REACT_APP_CODHEM_APP_BASE_URL} style={{ textDecoration: 'none', color: 'inherit' }}>
                <div className="flex flex-col">
                <span>
                  COD'HEM
                </span>
                  <span style={{ fontSize: '9px', fontWeight: 300 }}>
                  Literature Database
                </span>
                </div>
              </Link>
            </Button>
          </CardActions>
        </Card>
      </Box>

    </Box>
  );
};

export default HomePage;
