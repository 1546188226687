import React, { useCallback, useEffect, useRef, useState } from "react";

import PeriodicTable from "../Predict/PeriodicTable/PeriodicTable";
import { elements } from "../Predict/_data";

import toast, { Toaster } from "react-hot-toast";

import { PropertyData } from "../../_model";

import * as api from "../../api/literatureDatabase";
import CustomButton from "../Common/CustomButton";
import * as apiProperty from "../../api/literatureDatabase";
import DatabaseDataDisplay from "../Database/DatabaseDataDisplay";
import { scrollToBottom } from "../../utils";
import { getDoiData, getProperties } from "../../api/literatureDatabase";


const notifySuccess = () =>
  toast.success("Success", {
    icon: "✅",
    style: { borderRadius: "10px", background: "#22c55e", color: "#fff" },
  });

const notifyFailure = () =>
  toast.success("Update Failed", {
    icon: "❌",
    style: { borderRadius: "10px", background: "#ef4444", color: "#fff" },
  });

export interface CommonAdminPageProps {
  property?: PropertyData;
}
const PropertyDataTable = ({ property }: CommonAdminPageProps) => {

  const [isLoading, setIsLoading] = useState(false);

  const [dataDisplayComponent, setDataDisplayComponent] = useState(<></>);

  const scrollRef = useRef<null | HTMLDivElement>(null);

  const handleUpdateDataDisplayComponent = useCallback(
    (component: JSX.Element) => {
      setDataDisplayComponent(component);
      scrollToBottom(scrollRef);
    }, []);

  useEffect( () => {
    setIsLoading(true);

    getPropertyData()

    setIsLoading(false)
  }, [])

  const updatePropertyData = (row: any) => {
    try {



    } catch (error) {

    }
  }

  const getPropertyData = () => {
    const getData = async () => {
      setIsLoading(true)
      return await apiProperty.getProperties();
    }

    getData()
      .then((data) => {
        populateTable(data); // Pass the data to the populateTable function
        setIsLoading(false)
      })
      .catch((error) => {
        // Handle any errors that occurred during the getData() call
        //console.log("Error:", error);
        setIsLoading(false)
      });
  }


  const populateTable = (response: any) => {

    try {

      //console.log("respose: ", response)

      let properties = response.data.data.properties;

      if (Array.isArray(properties) && properties.length > 0) {

        let responseData: {}[] = [];

        const columnOrder: string[] = ['id', 'key', 'active', 'unique_id', 'DOI', 'Composition (atomic)', 'Type(s) of phase(s)', 'ρ (g/cm<sup>3</sup>)', 'HV', 'E (GPa)', 'σ<sub>y</sub> (MPa)', 'Type of test']; // Define your custom column order here

        properties.forEach( (row: { [x: string]: any; }) => {
          //const sortedKeys = Object.keys(row).sort((a, b) => a.length - b.length);

          const sortedKeys = columnOrder.filter((key) => key in row);

          let sortedObj: any = {};

          for (const key of sortedKeys) {
            sortedObj[key] = row[key];
          }

          responseData.push(sortedObj)
        })

        const columns_ = Object.keys(responseData[0]).map((key) => {
          return {
            id: key,
            label: key,
            minWidth: 200,
            align: 'center',
            format: (value: number) => value.toFixed(2)
          };
        });

        handleUpdateDataDisplayComponent(
          <DatabaseDataDisplay
            parsedData={[]}
            queryData={responseData}
            columns={columns_}
            title={false}
            updateFunction = {updatePropertyData}
          />
        );

      }

    } catch (error) {
      //console.log("error: ", error)
    }
  }


  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />

      {/* Upload file pane */}

      <div className="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4 mt-4 mb-10 border rounded-lg">

        <div className="border border-gray-300 p-4 rounded-lg bg">
          <h2 className="text-lg font-bold mb-2 text-center">
             Literature Property Data
          </h2>
        </div>

        <div className="border border-gray-300 p-4 rounded-lg">
          {dataDisplayComponent}
        </div>
      </div>

    </>
  );
};

export default PropertyDataTable;
