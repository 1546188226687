import { useEffect, useState } from "react";

import AdminSidebar from "./AdminSidebar";
import ElasticConstants from "./ElasticConstants";
import { PropertyData } from "../../_model";
import * as apiProperty from "../../api/property";
import VacancyFormationEnergy from "./VacancyFormationEnergy";
import VibrationalEntropy from "./VibrationalEntropy";
import CommonAdminPage from "./CommonAdminPage";
import LiteratureDatabase from "./LiteratureDatabase";
import DoiDataTable from "./DoiDataTable";
import PropertyDataTable from "./PropertyDataTable";
import Tabula from "./Tabula";
import TabulaV1 from "./TabulaV1";

const Admin = () => {
  const [activePropertyID, setActivePropertyID] = useState<number>(1);
  const [properties, setProperties] = useState<Array<PropertyData>>([]);

  useEffect(() => {
    const fetchProperties = async () => {
      const res = await apiProperty.getAll();
      setProperties(res.data);
    };

    fetchProperties();
  }, [activePropertyID]);

  const getPropertyComponent = () => {
    let property = properties.find((p) => p.id === activePropertyID);

    //console.log("property: ", property, activePropertyID)

    if (activePropertyID > 5) property = properties.find((p) => p.id === 1)

    if (!property)
      return (
        <div className="w-full text-center">
          <em>An error occurred.</em>
        </div>
      );

    switch (activePropertyID) {
      case 1:
        return <CommonAdminPage property={property} />;
      case 2:
        return <VacancyFormationEnergy property={property} />;
      case 4:
        return <CommonAdminPage property={property} />;
      case 5:
        return <CommonAdminPage property={property} />;
      case 6:
        return <LiteratureDatabase property={property} />;
      case 7:
        return <DoiDataTable property={property} />;
      case 8:
        return <PropertyDataTable property={property} />;
      case 9:
        return <TabulaV1 />;
      default:
        return (
          <div className="w-full h-full flex justify-center items-center">
            Under Construction
          </div>
        );
    }
  };

  return (
    <div className="w-full h-screen flex">
      <nav className="sticky w-1/6 min-w-fit">
        <AdminSidebar
          properties={properties}
          activePropertyID={activePropertyID}
          setActivePropertyID={setActivePropertyID}
        />
      </nav>
      <div className="p-4 w-5/6 h-full min-h-screen overflow-x-hidden overflow-y-scroll bg-neutral-100">
        {getPropertyComponent()}
      </div>
    </div>
  );
};

export default Admin;
