import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {useState, forwardRef, useEffect} from "react";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";

import bg from "./bg/predict_logo.png"
import {FormControl, InputLabel, Select, MenuItem, InputAdornment, IconButton} from "@mui/material";

import * as apiRole from "../../api/role";
import * as apiSector from "../../api/sector";
import * as apiCountry from "../../api/country";
import {Visibility, VisibilityOff} from "@mui/icons-material";

import * as apiUser from "../../api/user";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const boxstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const center = {
  position: "relative",
  top: "50%",
  left: "30%",
};

export default function Register() {

  const vertical = "top";
  const horizontal = "right";
  const navigate = useNavigate();

    const [sectorOptions, setSectorOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle confirm password visibility

    const [passwordsMatch, setPasswordsMatch] = useState(true); // State to track password match status

    const [institution, setInstitution] = useState("");

    const [passwordError, setPasswordError] = useState('');

    const [selectedRole, setSelectedRole] = useState(1);
    const [selectedSector, setSelectedSector] = useState(1);
    const [selectedCountry, setSelectedCountry] = useState(187);

    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [showErrorMsg, setShowErrorMsg] = useState("Failed! Enter correct username and password.");
    const [showSuccessMsg, setShowSuccessMsg] = useState("Success! Password Reset Link has been sent.");

    const handlePasswordBlur = () => {
        if (password.trim() === '') {
            setPasswordError('Password cannot be empty');
        } else {
            setPasswordError('');
        }
    };
    const handleInstitutionChange = (event) => {
        setInstitution(event.target.value);
    };

    const handleShowPasswordClick = () => {
        setShowPassword(!showPassword);
    };

    const handleShowConfirmPasswordClick = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const handleSectorChange = (event) => {
        setSelectedSector(event.target.value);
    };

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
    };


    useEffect(() => {

        getAllSectors().then();
        getAllRoles().then();
        getAllCountries().then();

    }, []);


    const getAllSectors = async () => {
        try {
            const res = await apiSector.getAll();

            // Convert the response data into the 'options' array
            const options = res.data.map((item) => {
                return {
                    value: item.id,
                    label: item.label,
                };
            });

            setSectorOptions(options)

        } catch (error) {
            //console.log(error)
        }
    };

    const getAllRoles = async () => {
        try {
            const res = await apiRole.getAll();

            // Convert the response data into the 'options' array
            const options = res.data.map((item) => {
                return {
                    value: item.id,
                    label: item.label,
                };
            });

            setRoleOptions(options)

        } catch (error) {
            //console.log(error)
        }
    };

    const getAllCountries = async () => {
        try {
            const res = await apiCountry.getAll();

            // Convert the response data into the 'options' array
            const options = res.data.map((item) => {
                return {
                    value: item.id,
                    label: item.label,
                };
            });

            setCountryOptions(options)
        } catch (error) {
            //console.log(error)
        }
    };

  const handleSubmit = async (event) => {

    event.preventDefault();

    try {

        setPasswordsMatch(true)

        if (password !== confirmPassword) {
            setPasswordsMatch(false)
            return
        }

        const payload = {
            email: username,
            password: password,
            institution: institution,
            sectorID: selectedSector,
            roleID: selectedRole,
            countryID: selectedCountry
        }

        const response = await apiUser.signup(payload)

        if (response.resp.statusCode === 200) {
            setShowSuccess(true)
            setShowSuccessMsg(response.resp.message)
            resetStates()

            navigate("/registration-success")

            return
        }

        setShowError(true)
        setShowErrorMsg(response.resp.message)

    } catch (error) {
        //console.log(error)
    }


  };

  const resetStates = () => {
      setInstitution("");
      setUsername("");
      setPassword("");
      setPassword("");
      setSelectedRole(1);
      setSelectedCountry(187);
      setSelectedSector(1);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
      setShowSuccess(false);
      setShowError(false);
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionComponent={TransitionLeft}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {showErrorMsg}
        </Alert>
      </Snackbar>
        <Snackbar
            open={showSuccess}
            autoHideDuration={3000}
            onClose={handleClose}
            TransitionComponent={TransitionLeft}
            anchorOrigin={{ vertical, horizontal }}
        >
            <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                {showSuccessMsg}
            </Alert>
        </Snackbar>
      <div
        style={{
          //backgroundImage: `url(${bgimg})`,
          backgroundSize: "cover",
          height: "100vh",
          color: "#f5f5f5",
        }}
      >
        <Box sx={boxstyle}>
          <Grid container>
            <Grid item xs={12} sm={12} lg={6}>
              <Box
                style={{
                  backgroundImage: `url(${bg})`,
                  backgroundSize: "auto 100%",
                  backgroundRepeat: "no-repeat", // Prevent repeating of background images
                  backgroundPosition: "center", // Position images at the center
                  marginTop: "40px",
                  marginLeft: "15px",
                  marginRight: "15px",
                  height: "63vh",
                  color: "#f5f5f5",
                }}
              ></Box>
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <Box
                style={{
                  backgroundSize: "cover",
                  height: "70vh",
                  minHeight: "500px",
                  backgroundColor: "#03457b",
                }}
              >
                <ThemeProvider theme={darkTheme}>
                  <Container>
                    <Box height={35} />
                    <Box sx={center}>
                      <Avatar
                        sx={{ ml: "85px", mb: "4px", bgcolor: "#ffffff" }}
                      >
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h4">
                        Create Account
                      </Typography>
                    </Box>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 2 }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            value={username}
                            onChange={handleUsernameChange}
                          />
                        </Grid>

                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <Stack direction="row" spacing={2}>
                              <TextField
                                  required
                                  fullWidth
                                  id="institution"
                                  label="Institution"
                                  name="institution"
                                  autoComplete="institution"
                                  value={institution}
                                  onChange={handleInstitutionChange}
                              />

                              <FormControl fullWidth required>
                                  <InputLabel id="sector-label">Sector</InputLabel>
                                  <Select
                                      labelId="sector-label"
                                      id="sector"
                                      name="sector"
                                      label="Sector"
                                      autoComplete="sector"
                                      value={selectedSector}
                                      onChange={handleSectorChange || null}
                                  >
                                      {sectorOptions.map((option) => (
                                          <MenuItem key={option.value} value={option.value}>
                                              {option.label}
                                          </MenuItem>
                                      ))}
                                  </Select>
                              </FormControl>
                            </Stack>
                          </Grid>

                          <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                              <Stack direction="row" spacing={2}>
                                  <FormControl fullWidth required>
                                      <InputLabel id="role-label">Role</InputLabel>
                                      <Select
                                          labelId="role-label"
                                          id="role"
                                          name="role"
                                          label="Role"
                                          autoComplete="role"
                                          value={selectedRole || null}
                                          onChange={handleRoleChange}

                                      >
                                          {roleOptions.map((option) => (
                                              <MenuItem key={option.value} value={option.value}>
                                                  {option.label}
                                              </MenuItem>
                                          ))}
                                      </Select>
                                  </FormControl>

                                  <FormControl fullWidth required>
                                      <InputLabel id="country-label">Country</InputLabel>
                                      <Select
                                          labelId="country-label"
                                          id="country"
                                          name="country"
                                          label="Country"
                                          autoComplete="country"
                                          value={selectedCountry || null}
                                          onChange={handleCountryChange}
                                          error={selectedCountry === null} // Apply error style when error state is true
                                      >
                                          {countryOptions.map((option) => (
                                              <MenuItem key={option.value} value={option.value}>
                                                  {option.label}
                                              </MenuItem>
                                          ))}
                                      </Select>
                                  </FormControl>
                              </Stack>
                        </Grid>

                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="new-password"
                            value={password}
                            onBlur={handlePasswordBlur}
                            error={Boolean(passwordError)}
                            helperText={passwordError}
                            onChange={handlePasswordChange}
                            // error={!passwordsMatch} // Set error state if passwords do not match
                            // helperText={!passwordsMatch && 'Passwords do not match'} // Show error message
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPasswordClick} edge="end">
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <TextField
                            required
                            fullWidth
                            name="confirmpassword"
                            label="Confirm Password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirmpassword"
                            autoComplete="new-password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            error={!passwordsMatch} // Set error state if passwords do not match
                            helperText={!passwordsMatch && 'Passwords do not match'} // Show error message
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowConfirmPasswordClick} edge="end">
                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                          <Button
                            type="submit"
                            variant="contained"
                            fullWidth="true"
                            size="large"
                            sx={{
                              mt: "15px",
                              mr: "20px",
                              borderRadius: 28,
                              color: "#ffffff",
                              minWidth: "170px",
                              backgroundColor: "#FF9A01",
                            }}
                          >
                            Register
                          </Button>
                        </Grid>
                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em"}}>
                          <Stack direction="row" spacing={2}>
                            <Typography
                              variant="body1"
                              component="span"
                              style={{ marginTop: "10px" }}
                            >
                              Already have an Account?{" "}
                              <span
                                style={{ color: "#beb4fb", cursor: "pointer" }}
                                onClick={() => {
                                    navigate("/login");
                                  }}
                              >
                                Sign In
                              </span>
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Container>
                </ThemeProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}
