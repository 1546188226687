import React, { useCallback, useEffect, useRef, useState } from "react";

import PeriodicTable from "../Predict/PeriodicTable/PeriodicTable";
import { elements } from "../Predict/_data";

import {
  Grid,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  DialogContentText
} from "@mui/material";

import toast, { Toaster } from "react-hot-toast";

import { PropertyData } from "../../_model";

import * as apiProperty from "../../api/literatureDatabase";
import DatabaseDataDisplay from "../Database/DatabaseDataDisplay";
import { scrollToBottom } from "../../utils";
import { BsSearch } from "react-icons/bs";
import { updateDoi } from "../../api/literatureDatabase";
import * as api from "../../api/literatureDatabase";
import DoiDataDisplay from "./DoiDataDisplay";

export interface CommonAdminPageProps {
  property?: PropertyData;
}
const DoiDataTable = ({ property }: CommonAdminPageProps) => {

  const [doiFile, setDoiFile] = useState<File>();

  const [isLoading, setIsLoading] = useState(false);

  const [strSearch, setStrSearch] = useState("");
  const [seqNo, setSeqNo] = useState<number>(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [failureDialogOpen, setFailureDialogOpen] = useState(false);

  const [successMessage, setSuccessMessage] = useState("Success");
  const [failureMessage, setFailureMessage] = useState("Failure");

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openSuccessDialog = () => {
    setSuccessDialogOpen(true);
  };

  const closeSuccessDialog = () => {
    setSuccessDialogOpen(false);
  };

  const openFailureDialog = () => {
    setFailureDialogOpen(true);
  };

  const closeFailureDialog = () => {
    setFailureDialogOpen(false);
  };

  const [dataDisplayComponent, setDataDisplayComponent] = useState(<></>);

  const scrollRef = useRef<null | HTMLDivElement>(null);

  const handleUpdateDataDisplayComponent = useCallback(
    (component: JSX.Element) => {
      setDataDisplayComponent(component);
      scrollToBottom(scrollRef);
    }, []);

  useEffect( () => {
    setIsLoading(true);

    const getData = async () => {
      setIsLoading(true)
      return await apiProperty.getDoiData("");
    }

    getData()
      .then((data) => {
        populateTable(data); // Pass the data to the populateTable function
        setIsLoading(false)
      })
      .catch((error) => {
        // Handle any errors that occurred during the getData() call
        //console.log("Error:", error);
        setIsLoading(false)
      });

    setIsLoading(false)
  }, [])

  const onClickQuery = async () => {
    try {
      setIsLoading(true)

      const response =  await apiProperty.getDoiData(strSearch);

      //console.log("onClick: ", response)
      populateTable(response)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const showModel = () => {

    if (!strSearch || strSearch.length < 0) {
      setFailureMessage("Please enter doi")
      openFailureDialog()
      return
    }

    setIsModalOpen(true)
  }
  const updateDoi = async () => {
    try {

      closeModal()
      setIsLoading(true)

      const res = await apiProperty.updateDoi(strSearch, seqNo);

      //console.log("res: ", res)

      if (res.data.statusCode !== 200) {
      }

      const response =  await apiProperty.getDoiData(strSearch);

      populateTable(response)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const populateTable = (response: any) => {

    if (Array.isArray(response?.data.data) && response?.data.data.length > 0) {

      let responseData: {}[] = [];

      const columnOrder: string[] = ['id', 'DOI', 'active']; // Define your custom column order here

      response?.data.data.forEach( (row: { [x: string]: any; }) => {
        //const sortedKeys = Object.keys(row).sort((a, b) => a.length - b.length);

        // const sortedKeys = columnOrder.filter((key) => key in row);

        const sortedKeys = columnOrder;

        let sortedObj: any = {};

        for (const key of sortedKeys) {
          sortedObj[key] = row[key];
        }

        responseData.push(sortedObj)
      })

      const columns_ = Object.keys(responseData[0]).map((key) => {
        return {
          id: key,
          label: key,
          minWidth: 200,
          align: 'center',
          format: (value: number) => value.toFixed(2)
        };
      });

      //console.log("before123: ", responseData, columnOrder)

      // handleUpdateDataDisplayComponent(
      //   <DoiDataDisplay
      //     parsedData={[]}
      //     queryData={responseData}
      //     columns={columnOrder}
      //     title={false}
      //   />
      // );

      handleUpdateDataDisplayComponent(
        <DatabaseDataDisplay
          parsedData={[]}
          queryData={responseData}
          columns={columns_}
          title={false}
        />
      );

    }
  }

  const onDoiDataUpload = () => {
    //console.log("onDoiDataUpload data: ", doiFile)

    if (doiFile === undefined)
      return

    setIsLoading(true);

    const formData = new FormData();
    if (doiFile) {
      formData.append("file", doiFile);
    }

    try {

      const response = api.uploadDoiData(formData)

    } catch (error) {
      // Handle network errors
      console.error("Network error:", error);
      //notifyFailure()
    }

    setIsLoading(false);
    //notifySuccess()

  }

  const onSearchStringChange = (e: any) => {
    setStrSearch(e.target.value)
  }


  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />

      {/* Upload file pane */}

      <div className="flex flex-col w-full h-full bg-white rounded-md p-4 gap-4 mt-4 mb-10 border rounded-lg">

        <div className="border border-gray-300 p-4 rounded-lg bg">
          <h2 className="text-lg font-bold mb-2 text-center">
            DOI Literature Data
          </h2>
        </div>

        {/*<div className="border border-gray-300 p-4 rounded-lg">*/}
        {/*  <div className="flex items-center gap-4">*/}
        {/*    <div className="w-full flex items-center gap-4">*/}
        {/*      <span> DOI File</span>*/}
        {/*      <label className="min-w-max shadow-md rounded-md text-center py-3 px-8 bg-white font-light hover:cursor-pointer hover:bg-neutral-200">*/}
        {/*        <input*/}
        {/*          //ref={inputDataFileRef}*/}
        {/*          type="file"*/}
        {/*          accept=".txt"*/}
        {/*          className="hidden"*/}
        {/*          //onChange={(e) => onChangeFile(e.target.files, false)}*/}
        {/*          disabled={isLoading}*/}
        {/*        />*/}
        {/*        Choose a csv file*/}
        {/*      </label>*/}
        {/*      {doiFile && <span>{doiFile.name}</span>}*/}
        {/*    </div>*/}

        {/*    {isLoading ? (*/}
        {/*      <img*/}
        {/*        src="/loading.gif"*/}
        {/*        alt="loading..."*/}
        {/*        className="aspect-square max-h-8"*/}
        {/*      />*/}
        {/*    ) : (*/}
        {/*      <></>*/}
        {/*    )}*/}

        {/*    <button*/}
        {/*      type="button"*/}
        {/*      className={*/}
        {/*        "min-w-max py-2 px-6 rounded-md bg-purple-700 text-white font-light transition-all "*/}
        {/*        +*/}
        {/*        ( doiFile !== undefined*/}
        {/*          ? "hover:bg-purple-800"*/}
        {/*          : "opacity-70 hover:cursor-default")*/}
        {/*      }*/}
        {/*      onClick={() => onDoiDataUpload()}*/}
        {/*    >*/}
        {/*      Upload*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*</div>*/}


        {/* Dialog */}
        <Dialog open={isModalOpen && strSearch.length > 0} onClose={closeModal}>
          <DialogTitle>Enter Sequence Number</DialogTitle>
          <DialogContent>
            <TextField
              type="number" // Set the input type to "number" to allow only numeric input
              inputProps={{ min: 0 }} // Specify a minimum value of 0
              value={seqNo === 0 ? '0' : seqNo.toString()}  // Use 0 as the value when seqNo is 0
              onChange={(e) => setSeqNo(parseFloat(e.target.value) || 0)} // Parse the input value and handle NaN by using 0 as the default value
              placeholder="Enter Sequence Number"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <Button onClick={updateDoi} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>

        {/* Success Dialog */}
        <Dialog open={successDialogOpen} onClose={closeSuccessDialog}>
          <DialogTitle>Success</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {successMessage || "The operation was successful."}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeSuccessDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {/* Failure Dialog */}
        <Dialog open={failureDialogOpen} onClose={closeFailureDialog}>
          <DialogTitle>Failure</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {failureMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeFailureDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <div className="border border-gray-300 p-4 rounded-lg">
          <div className="px-4 py-2 bg-neutral-100 rounded-lg mb-4">
            <div className="w-full flex flex-col lg:flex-row items-center justify-between">
              <div className="flex flex-col lg:flex-row gap-4 w-full min-w-max">
                <div className="flex min-w-max drop-shadow-md">
                  <div className="h-full px-3 rounded-tl-md rounded-bl-md text-white bg-sky-700">
                    <BsSearch className="h-full" strokeWidth={1} />
                  </div>
                  <input
                    type="text"
                    className="px-4 py-2 rounded-tr-md rounded-br-md font-light min-w-[32rem] focus:outline-none"
                    value={strSearch}
                    onChange={onSearchStringChange}
                    placeholder="Search DOI "
                  />
                </div>
              </div>
              <div className="flex flex-row-reverse w-full min-w-max items-center gap-4">
                <button
                  type="button"
                  className={
                    "px-12 py-2 text-white rounded-md shadow-md transition-all bg-orange-500 hover:bg-orange-600"
                  }
                  onClick={() => showModel()}
                >
                  Add
                </button>
                <button
                  type="button"
                  className={
                    "px-12 py-2 text-white rounded-md shadow-md transition-all bg-orange-500 hover:bg-orange-600"
                  }
                  onClick={() => onClickQuery()}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          {!isLoading ? dataDisplayComponent : ''}
        </div>
      </div>

    </>
  );
};

export default DoiDataTable;
