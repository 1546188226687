import * as apiProperty from "../../api/literatureDatabase";

import React, { useCallback, useEffect, useRef, useState } from "react";

import { FaToggleOff, FaToggleOn } from "react-icons/fa";

import ModalLogin from "../Predict/ModalLogin";
import { elements, elementsMap } from "../Predict/_data";

import { Toaster } from "react-hot-toast";
import { scrollToBottom, notifySuccess, notifyFailure } from "../../utils";
import CustomPeriodicTable from "./CustomPeriodicTable";
import DatabaseDataDisplay from "./DatabaseDataDisplay";
import parse from "html-react-parser";
import D3BubbleChart from "./D3BubbleChart";
import UseTableComponent from "./UseTableComponent";
import {
  COMPOSITION,
  DATABASE_COLOR_SCHEME_OPTIONS,
  DATABASE_TYPE_OF_PHASES_FILTER_OPTIONS,
  DROPDOWN_KEYS
} from "../Common/constants/Constants";
import { ROMContainer } from "./ROM/ROMContainer";

export interface NumberRange {
  from?: number;
  to?: number;
}

export interface ElementFilter {
  from: number;
  to?: number;
  exclude?: boolean;
}

export default function Database() {
  const isLoggedIn = localStorage.getItem("email") !== null;

  const scrollRef = useRef<null | HTMLDivElement>(null);

  const [propertiesFilter, setPropertiesFilter] = useState<Array<{
    is_filter: boolean;
    options?: string[];
    type?: string;
    label?: string;
    key: string, value: string, id: number }>>();

  const [activePropertyID, setActivePropertyID] = useState<number>(1);

  const [properties, setProperties] = useState([])

  const [activeProperties, setActiveProperties] = useState<string[]>([]);
  const [propertiesToValueRange, setPropertiesToValueRange] = useState<
    Map<string, NumberRange>
  >(new Map());

  const [compositionsToValueRange, setCompositionsToValueRange] = useState<
    Map<string, NumberRange>
  >(new Map());

  const [selectedElmsToFilter, setSelectedElmsToFilter] = useState<
    Map<number, ElementFilter>
  >(new Map());

  const [elementsFilters, setElementsFilters] = useState<
    Map<string, ElementFilter>
  >(new Map());

  const [enabledElms, setEnabledElms] = useState<number[]>([]);
  const [selectedElms, setSelectedElms] = useState<number[]>([]);


  const [data, setData] = useState([])

  const [isLoading, setIsLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  const [dataDisplayComponent, setDataDisplayComponent] = useState(<></>);

  const [selectedYAxis, setSelectedYAxis] = useState(); // Default Y-axis property
  const [selectedXAxis, setSelectedXAxis] = useState(); // Default Y-axis property

  const [selectedColorProperty, setSelectedColorProperty] = useState(
    DATABASE_COLOR_SCHEME_OPTIONS[0]?.key ?? '' // Use optional chaining with nullish coalescing
  );

  const [distinctPhases, setDistinctPhases] = useState<string[]>([]); // State for distinct types of phases


  const [selectedTypeOfPhaseFilter, setSelectedTypeOfPhaseFilter] = useState<string>("");

  const [responseData, setResponseData] = useState<any>([]);
  const [columns, setColumns] = useState<any>([])

  const handleUpdateDataDisplayComponent = useCallback(
    (component: JSX.Element) => {
      setDataDisplayComponent(component);
      scrollToBottom(scrollRef);
    }, []);

  // Populate properties on mount
  useEffect(() => {
    const populateProperties = async () => {
      return await apiProperty.getConfigs();
    }
    const getData = async () => {
      setIsLoading(true)
      return await apiProperty.getData();
    }

    const populateEnabledElements = async () => {
      return await apiProperty.getConfigs();
    }

    if (isLoggedIn) {
      populateProperties().then((data) => {

        setProperties(data.data.data.properties)

        //console.log("data populateProperties: ", data.data.data.properties)

        // Filter out rows where active is false
        const filteredProperties = data.data.data.properties.filter((property: any) =>
          {
            return property.active && property.is_filter;
          }
        );

        // set the filtered properties as propertiesFilter
        setPropertiesFilter(filteredProperties);
      }).catch((error) => {
        // Handle any errors that occurred during the getData() call
        //console.log("Error:", error);
      });
      getData()
        .then((data) => {
          populateTable(data); // Pass the data to the populateTable function
          setIsLoading(false)
        })
        .catch((error) => {
          // Handle any errors that occurred during the getData() call
          //console.log("Error:", error);
          setIsLoading(false)
        });

      populateEnabledElements().then((res) => {

        //console.log("data populateEnabledElements: ", res.data.data)

        let elements: number[] = []
        res.data.data.elements.forEach((elm: any) => {
          elements.push(elm.atomicNum);
        });

        //console.log("data populateEnabledElements: ", elements)

        setEnabledElms(() => elements);
      }).catch((error) => {
        //console.log("error: ", error)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateHeaders = async () => {
    return await apiProperty.getHeaders();
  }
  const populateTable = async (response: any) => {
    try {
      if (Array.isArray(response?.data.data) && response?.data.data.length > 0) {

        let responseData: {}[] = [];

        setData(response.data.data)

        //const columnOrder: string[] = ['unique_id', 'DOI', 'Composition (atomic)', 'Type(s) of phase(s)', 'ρ (g/cm<sup>3</sup>)', 'HV', 'E (GPa)', 'σ<sub>y</sub> (MPa)', 'Type of test']; // Define your custom column order here

        //let columnOrder: string[] = ["unique_id", "Composition (atomic)", "Type(s) of phase(s)", 'ρ (g/cm<sup>3</sup>)', 'E (GPa)', 'σ<sub>y</sub> (MPa)', 'Type of test']; // Define your custom column order here

        let columnOrder: string[] = []; // Define your custom column order here

        // let columnOrder: string[] = [];
        let res = await populateHeaders();

        let altHeaders: any = {}
        res.data.data.properties.forEach((property: any) => {

          if (property.active === true) {
            columnOrder.push(property.key)
          }

          // if (property.alt !== undefined) {
          //   altHeaders[property.key] = property.alt
          // }

          if (property.label !== undefined) {
            altHeaders[property.key] = property.label
          }

        });


        //console.log("populateHeaders: ", res, columnOrder)
        let distinctTypesOfPhases = new Set<string>();
        response?.data.data.forEach( (row: { [x: string]: any; }) => {

          const sortedKeys = columnOrder;

          let sortedObj: any = {};

          for (const key of sortedKeys) {
            //sortedObj[key] = row[key];

            if (altHeaders[key]) {
              sortedObj[altHeaders[key]] = row[key]
            } else {
              sortedObj[key] = row[key]
            }

            // Check and add "Type(s) of phase(s)" to the Set if it's not empty or undefined
            if (key === 'Type(s) of phase(s)' && row[key] && typeof row[key] === 'string' && row[key].trim() !== '') {
              // distinctTypesOfPhases.add(row[key].trim());
              distinctTypesOfPhases.add("FCC");
              distinctTypesOfPhases.add("BCC");
              distinctTypesOfPhases.add("OTHER");
            }
          }

          responseData.push(sortedObj)
        })

        //console.log("populateHeaders: ", res, responseData)

        const columns_ = Object.keys(responseData[0]).map((key) => {
          return {
            id: key,
            label: key,
            width: key.length*10,
            align: 'center',
            format: (value: number) => value.toFixed(2)
          };
        });

        //console.log("before redering123 responseData: ", responseData, columns_)

        setResponseData(responseData);
        setColumns(columns_);
        setDistinctPhases(Array.from(distinctTypesOfPhases));

        handleUpdateDataDisplayComponent(
          <DatabaseDataDisplay
            parsedData={[]}
            queryData={responseData}
            columns={columns_}
          />
        );

      }
    } catch (error) {
      //console.log("error: ", error)
    }
  }

  const onClickElement = (symbol: number) => {

    //@ts-ignore
    let elem: string = elements.find((elm) => {
      if (elm.atomicNum === symbol) {
        return elm.symbol;
      }
    })?.symbol;

    if (elementsFilters.has(elem)) {
      elementsFilters.delete(elem);
    } else {
      elementsFilters.set(elem, { from: 0, exclude: false });
    }

    // For composition percentage filter
    if (selectedElmsToFilter.has(symbol)) {
      selectedElmsToFilter.delete(symbol);

      compositionsToValueRange.delete(elem);
    } else {
      selectedElmsToFilter.set(symbol, { from: 0 });
    }

    setSelectedElmsToFilter(new Map(selectedElmsToFilter));
    setSelectedElms(Array.from(selectedElmsToFilter.keys()));


  };

  const getPropertyComponent = () => {
    // const property = properties.find((p) => p.id === activePropertyID);
    // if (!property) return <></>;

    switch (1) {
      default:
        return (
          <CustomPeriodicTable
            enabledElms={enabledElms}
            onClickElement={onClickElement}
            selectedElms={selectedElms}
          />
        );
    }
  };

  useEffect( () => {

    try{
      if (typeof errorMsg === 'string' && errorMsg.length > 0) {
        notifyFailure(errorMsg);
      }
    } catch (e) {
      notifyFailure("something went wrong")
      //console.log("useEffect Method: ", e)
    }


  }, [errorMsg])

  const handleFocus = (e: React.FocusEvent<any>) => {
    if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
      e.target.select();
    }
  };


  const onToggleProperty = (label: string) => {
    // For property value filter
    let idx = activeProperties.indexOf(label);

    //console.log("onToggleProperty: ", label, "idx: ", idx, activeProperties)

    if (idx === -1) {
      activeProperties.push(label);
      setActiveProperties([...activeProperties]);
      return;
    }

    activeProperties.splice(idx, 1);
    setActiveProperties([...activeProperties]);

    propertiesToValueRange.delete(label)

    // propertiesToValueRange.set(label, { from: 0 });
    // setPropertiesToValueRange(new Map(propertiesToValueRange));
  };

  const onChangePropPercentFrom = (label: string, valueFrom: number) => {

    //console.log("valueFrom: ", valueFrom)

    if (activeProperties.indexOf(label) === -1) activeProperties.push(label);

    if (valueFrom < 0) return;
    const to = propertiesToValueRange.get(label)?.to;

    propertiesToValueRange.set(label, { from: valueFrom, to: to });
    setPropertiesToValueRange(new Map(propertiesToValueRange));

  };

  const onChangePropPercentTo = (label: string, valueTo: number) => {

    //console.log("onChangePropPercentTo: ", valueTo)

    //if ( valueTo > 100) return

    if (activeProperties.indexOf(label) === -1) activeProperties.push(label);

    let from = propertiesToValueRange.get(label)?.from;
    //if (from === undefined) from = 0;

    propertiesToValueRange.set(label, { from: from, to: valueTo });
    setPropertiesToValueRange(new Map(propertiesToValueRange));
  };


  const onToggleElementFilter = (symbol: string) => {
    const filter = elementsFilters.get(symbol);
    const updatedFilter: ElementFilter = {
      from: filter?.from ?? 0,
      to: filter?.to,
      exclude: !filter?.exclude
    };
    elementsFilters.set(symbol, updatedFilter);
    setElementsFilters(new Map(elementsFilters));
  };


  const onChangeCompositionPercentFrom = (label: string, valueFrom: number) => {

    //console.log("valueFrom: ", valueFrom)

    //if (activeProperties.indexOf(label) === -1) activeProperties.push(label);

    if (valueFrom < 0) return;
    const to = compositionsToValueRange.get(label)?.to;

    compositionsToValueRange.set(label, { from: valueFrom, to: to });
    setCompositionsToValueRange(new Map(compositionsToValueRange));

  };

  const onChangeCompositionPercentTo = (label: string, valueTo: number) => {

    //console.log("onChangePropPercentTo: ", valueTo)

    if ( valueTo > 100) return

    //if (activeProperties.indexOf(label) === -1) activeProperties.push(label);

    let from = compositionsToValueRange.get(label)?.from;
    //if (from === undefined) from = 0;

    compositionsToValueRange.set(label, { from: from, to: valueTo });
    setCompositionsToValueRange(new Map(compositionsToValueRange));
  };

  const handleQueryDatabase = async () => {
    try {

      setIsLoading(true);
      reset();

      // Convert DropdownProperties (object) to Map<string, string>
      const dropdownPropertiesMap = new Map<string, string>(
        Object.entries(dropdownProperties) // Convert object to an array of key-value pairs
      );

      let res = await apiProperty.queryDataByFilter(dropdownPropertiesMap, propertiesToValueRange, compositionsToValueRange, elementsFilters)

      await populateTable(res); // Pass the data to the populateTable function
      setIsLoading(false)

    } catch (error) {
      //console.log(error)
      setIsLoading(false);
    }
  }

  const reset = () => {
    handleUpdateDataDisplayComponent(
      <DatabaseDataDisplay
        parsedData={[]}
        queryData={[]}
        columns={[]}
      />
    );
  }

  // Function to handle Y-axis change
  function handleYAxisChange(event: any) {
    setSelectedYAxis(event.target.value);
  }

  function handleXAxisChange(event: any) {
    setSelectedXAxis(event.target.value);
  }

  function handleColorScheme (event: any) {

    //console.log("event.target.value: ", event.target.value)

    // if (event.target.value === COMPOSITION) {
    //   setSelectedTypeOfPhaseFilter("")
    // }

    setSelectedColorProperty(event.target.value);
  }

  function handleTypeOfPhaseFilter (event: any) {
    setSelectedTypeOfPhaseFilter(event.target.value);
  }

  const selectStyle = {
    minWidth: '150px', // Set your desired minimum width here
    margin: '1rem'
  };

  const [romProperty, setROMProperty] = useState<any>({key: "", value: ""});
  const [romData, setROMData] = useState<any>([]);
  const [isROMLoading, setIsROMLoading] = useState<boolean>(false);
  const handleQueryDatabaseForROM = async () => {
    try {

      setIsROMLoading(true)
      let res = await apiProperty.queryDataByFilterForROM(propertiesToValueRange, compositionsToValueRange, elementsFilters, romProperty.value, romProperty.key)

      setROMData(res.data.data);
      setIsROMLoading(false)

    } catch (error) {
      // console.log(error)
      // setIsLoading(false);
      setIsROMLoading(false)
    }
  }

  // Define the type for dropdown properties with a dynamic string key
    type DropdownProperties = {
      [key: string]: string; // Key is a string, value is the selected string option
    };

  // Initialize state with the correct type
    const [dropdownProperties, setDropDownProperties] = useState<DropdownProperties>({});

    const onChangePropDropdown = (key: string, value: string | undefined, isActive: boolean) => {

      //console.log("onChangePropDropdown: ", key)

      setDropDownProperties((prevProps) => {
        const updatedProps = { ...prevProps };

        if (key !== "Type(s) of phase(s)") {
          delete updatedProps[key];
          return updatedProps;
        }

        if (isActive && value !== undefined) {
          // Update the properties state based on the key and the selected value
          updatedProps[key] = value;
        } else {
          // Remove the key and reset its value (instead of just deleting it)
          delete updatedProps[key];
        }

        return updatedProps;
      });
    };

  return (
    <>
      {isLoading ? (
        <div className="fixed top-0 w-full h-full z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <img
            src="/loading.gif"
            alt="loading..."
            className="max-h-[25%] aspect-square"
          />
        </div>
      ) : (
        <></>
      )}

      <Toaster position="top-right" reverseOrder={true} />

      {isLoggedIn ? (
        <>
          <div>
            <div className="flex flex-row gap-2 border-2 border-grey-900 w-full">

              <div className="flex justify-start gap-1">
                <div className="p-4 bg-orange-500 flex flex-col rounded-lg max-h-[500px] overflow-scroll ">

                  <h1 className="font-light text-center py-1 bg-white rounded-md text-lg mb-4">
                    <b>Filter by Properties</b>
                  </h1>

                  {propertiesFilter && propertiesFilter.map((p, _) => {

                    const isActive = activeProperties.indexOf(p.key) !== -1;

                    const vRange = {
                      from: propertiesToValueRange.get(p.key)?.from,
                      to: propertiesToValueRange.get(p.key)?.to,
                    };

                    // if (vRange.from === undefined) {
                    //   return <></>;
                    // }

                    return (
                      <div key={p.key} className="w-full mb-2">
                        <div
                          className={
                            `flex flex-col w-full py-3 bg-white shadow-md rounded-md 
        items-center ${isActive ? "" : "brightness-90"}`
                          }
                          onFocus={handleFocus}
                        >
                          {/* Label and Toggle */}
                          <div className="flex justify-between items-center w-full px-2 mb-1">
                            {/* Left: Label */}
                            <h1 className="flex items-center gap-1">
                              {p.label ? (<b>{parse(p.label)}</b>) : <b>{parse(p.key)}</b>}
                            </h1>

                            {/* Right: Toggle */}
                            <div className="flex items-center gap-1">
                              <p className="text-xs min-w-max">{isActive ? "active" : "inactive"}</p>
                              {isActive ? (
                                <FaToggleOn
                                  className="hover:cursor-pointer text-green-600"
                                  size={25}
                                  onClick={() => {
                                    onToggleProperty(p.key)
                                    onChangePropDropdown(p.key, undefined, false)
                                  }
                                }
                                />
                              ) : (
                                <FaToggleOff
                                  className="hover:cursor-pointer text-red-600"
                                  size={25}
                                  onClick={() => {
                                    onToggleProperty(p.key)
                                    onChangePropDropdown(p.key, "FCC", true)
                                  }
                                }
                                />
                              )}
                            </div>
                          </div>

                          {/* Inputs for Range or Dropdown based on the key or type */}
                          <div className="flex items-center justify-center gap-2 px-10">
                            {p.type === "dropdown" || DROPDOWN_KEYS.includes(p.key) ? (
                              // Render a select dropdown for specific keys or if p.type is dropdown
                              <select
                                className="w-full border-b-2 outline-none focus:border-neutral-400"
                                value={dropdownProperties[p.key] ?? ""}
                                onChange={(e) =>
                                  onChangePropDropdown(p.key, e.target.value, isActive)
                                }
                                onFocus={handleFocus}
                              >
                                {p.options?.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            )  : (
                              // Otherwise render inputs for the range
                              <>
                                <input
                                  className="w-16 border-b-2 text-right outline-none focus:border-neutral-400"
                                  type="number"
                                  min={0}
                                  value={Number(vRange.from)}
                                  onChange={(e) =>
                                    onChangePropPercentFrom(p.key, Number(e.target.value))
                                  }
                                  onFocus={handleFocus}
                                />
                                <span>to</span>
                                <input
                                  className="w-16 border-b-2 text-right outline-none focus:border-neutral-400"
                                  type="number"
                                  min={0}
                                  value={vRange.to === undefined ? "" : vRange.to}
                                  onChange={(e) =>
                                    onChangePropPercentTo(p.key, Number(e.target.value))
                                  }
                                  onFocus={handleFocus}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                  );
                  })}
                </div>
              </div>
              <div className="flex justify-between gap-2 w-full">
                <div className="flex justify-between gap-2">
                  {getPropertyComponent()}
                </div>
                <div className="flex justify-center">
                  <div className="p-4 bg-orange-500 flex flex-col rounded-lg max-h-[500px] overflow-scroll ">

                    <h1 className="font-light text-center py-1 bg-white rounded-md text-lg mb-4 p-2">
                      <b>Filter by Composition (%)</b>
                    </h1>

                    <div className="flex flex-col gap-2 overflow-y-auto font-light w-full">
                      {Array.from(selectedElmsToFilter.entries()).length === 0 ? (
                        <div className="flex px-4 text-center font-light text-white">
                          Select an element to narrow your search by composition.
                        </div>
                      ) : (
                        <>
                        </>
                      )}

                      {Array.from(selectedElmsToFilter.entries()).map(
                        ([atomicNum, pRange], _) => {


                          const elm = elements.find((elm) => elm.atomicNum === atomicNum);
                          if (!elm) return <></>;


                          // @ts-ignore
                          let symbol: string = elements.find((elm) => {
                            if (elm.atomicNum === atomicNum) {
                              return elm.symbol;
                            }
                          })?.symbol;

                          let fromRange: number | undefined;
                          let toRange: number | undefined;
                          compositionsToValueRange.forEach((value, key) => {
                            if (key === symbol) {
                              fromRange = value.from
                            }

                            if (key === symbol) {
                              toRange = value.to
                            }
                          });

                          // Check the exclude state from elementsFilters
                          const isExcluded = elementsFilters.get(symbol)?.exclude ?? false;

                          return (
                            <div key={atomicNum} className="w-full gap-2">
                              <div
                                className={
                                  "flex flex-col w-full py-3 px-10 bg-white shadow-md rounded-md"
                                }
                                onFocus={handleFocus}
                              >
                                <div className="flex justify-between items-center w-full px-2 mb-1">
                                  <h1 className="flex items-center gap-1">
                                    <b>Atom: <strong>{symbol}</strong></b>
                                    {/*{p.uom ? "(" + p.uom + ")" : ""}*/}
                                  </h1>
                                  {isExcluded ? (
                                    <div className="flex items-center gap-1">
                                      <p className="text-xs min-w-max">excluded</p>
                                      <FaToggleOff
                                        className="hover:cursor-pointer text-red-600"
                                        size={25}
                                        onClick={() => onToggleElementFilter(symbol)}
                                      />
                                    </div>
                                  ) : (
                                    <div className="flex items-center gap-1">
                                      <p className="text-xs min-w-max">included</p>
                                      <FaToggleOn
                                        className="hover:cursor-pointer text-green-600"
                                        size={25}
                                        onClick={() => onToggleElementFilter(symbol)}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="flex justify-center gap-2 px-2">
                                  <input
                                    className="w-16 border-b-2 text-right outline-none focus:border-neutral-400"
                                    type="number"
                                    min={0}
                                    // step={0.1}
                                    value={fromRange === undefined ? undefined : parseInt(String(fromRange), 10).toString()}
                                    onChange={(e) =>
                                      onChangeCompositionPercentFrom(
                                        symbol,
                                        Number(e.target.value)
                                      )
                                    }
                                    onFocus={handleFocus}
                                  />
                                  to
                                  <input
                                    className="w-16 border-b-2 text-right outline-none focus:border-neutral-400"
                                    type="number"
                                    //min={0}
                                    // step={0.1}
                                    value={toRange === undefined ? undefined : parseInt(String(toRange), 10).toString()}
                                    onChange={(e) =>
                                      onChangeCompositionPercentTo(
                                        symbol,
                                        Number(e.target.value)
                                      )
                                    }
                                    onFocus={handleFocus}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}

                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="pt-2 pb-4">
              <div className="flex flex-row items-center justify-center"> {/* Updated line */}
                <button
                  type="button"
                  className={
                    "py-3 px-8 my-auto rounded-md bg-purple-700 text-white font-light transition-all m-2 hover:bg-purple-800"
                  }
                  onClick={() => {
                    handleQueryDatabase().then()
                  }}
                >
                  Query
                </button>
              </div>
            </div>

            {dataDisplayComponent}

            {<D3BubbleChart
              x_title={selectedXAxis}
              y_title={selectedYAxis}
              data={data}
              responseData={responseData}
              columns={columns}
              selectedXAxis={selectedXAxis}
              handleXAxisChange={handleXAxisChange}
              selectStyle={selectStyle}
              propertiesFilter={propertiesFilter}
              selectedYAxis={selectedYAxis}
              handleYAxisChange={handleYAxisChange}
              selectedColorProperty={selectedColorProperty}
              handleColorScheme={handleColorScheme}
              selectedTypeOfPhaseFilter={selectedTypeOfPhaseFilter}
              handleTypeOfPhaseFilter={handleTypeOfPhaseFilter}
              distinctPhases={distinctPhases}
              />
            }

            <ROMContainer
              data={romData}
              setROMProperty={setROMProperty}
              romProperty={romProperty}
              handleQueryDatabaseForROM={handleQueryDatabaseForROM}
              setROMData={setROMData}
              isROMLoading={isROMLoading}
            />

          </div>
        </>
      ) : (
        <ModalLogin />
      )}
    </>
  );
}
